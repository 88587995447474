.container {
    height: 100vh;
    background-color: white;
}

.content {
    height: 100vh;
    overflow-y: auto;
    padding-top: 10px;
    padding-bottom: 150px;
}

.description {
    font-size: 14px;
    color: #696969;
    text-align: center;
    margin: 15px;
}

.textError {
    /* margin-top: 4%; */
    margin-bottom: 6%;
    margin-left: 5%;
    margin-right: 5%;
}

.button {
    width: 50%;
    font-size: 15px;
    text-align: center;
    background-color: #148235;
    margin-left: auto;
    cursor: pointer;
    margin-top: 10px;
}

.button:hover {
    color: white;
    background-color: #3d5f92;
    margin-left: auto;
}

.buttonBox {
    display: flex;
    justify-content: center;
    text-align: center;
}

.boxCategories {
    display: flex;
    justify-content: center;
    width: 100%;
}

