.page {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    background-color: white;
}

.tab {
    height: 75px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
}

.tabLabel {
    width: 50%;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    color: #808080;
    border-bottom: 2px solid #F1F1F1;
    overflow-x: hidden;
}

.tabLabelActive {
    width: 50%;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    color: #148235;
    border-bottom: 2px solid #148235;
    overflow-x: hidden;
}

.tabView {
    height: 91vh;
    display: none;
}

.tabViewActive {
    height: 91vh;
    display: block;
}