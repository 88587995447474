.container {
    height: 80vh;
    background-color: white;
}

.content {
    height: 91vh;
    overflow-y: auto;
    padding-bottom: 50px;
    background-color: white;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px #f1f1f1 solid;
}

.title {
    color: #414141;
    font-weight: bold;
    text-align: center;
}


.agoraContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.agoraContainer:hover {
    background-color: #F1F1F1;
}

.agoraIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.agoraIcon {
    width: 30px;
    height: 30px;
}

.agoraTitle {
    font-weight: 600;
    font-size: 18px;
    margin-left: 20px;
    margin-top: 5px
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #148235;
}

.agoraPicture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid #148235;
}