.container {
    width: 100%;
    display: flex;
    margin-top: 10px;
    overflow-x: auto ;
}


.boxAccount {
    width: 55px;
    padding-top: 5px;
    margin-left: 15px;
    cursor: pointer;
}

.profilePicture {
    width: 55px;
    height: 55px;
    margin-left: 4px;
    border-radius: 5px;
    border: 1px #C0C0C0 solid;
}

.profilePicture:hover {
    border: 2px #148235 solid;
}

.name {
    color: #148235;
    width: 55px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
}