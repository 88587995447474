.appstore::-webkit-scrollbar {
    display: none;
}

/* CARD STORE */
.appstore  {
    height: 100vh;
    display: flex;  
    justify-content: center;
    position: fixed;
    width: 30%;
    text-align: center;
    padding-bottom: 100px;
    overflow-y: auto;
}

.appstore .card-content {
    width: 80%;
}

.appstore .card-content .logo {
    width: 150px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.appstore .card-content .img-playstore {
    width: 70%;
    height: 14%;
}

.appstore .card-content .img-appstore {
    width: 70%;
    height: 13%;
    margin-top: -8px;
}

.appstore .card-content .img-appgallery {
    width: 60%;
    height: 10%;
}

.appstore .card-content .select-language {
    font-size: 13px;
    margin-top: 30px;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
}

.appstore .card-content .terms-conditions {
    margin-top: 50px;
    margin-bottom: 5%;
}

.appstore .card-content .copyrigth {
    font-size: 14px;
    color: #808080;
}

